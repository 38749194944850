import { AppBar, Toolbar } from "@mui/material";
import synoriqLogo from "../../assets/images/synoriqLogo.svg";

const Header = () => {
  return (
    <AppBar
      sx={{
        backgroundColor: "#fff",
        boxShadow: "none",
      }}
      position="sticky"
    >
      <Toolbar>
        <img
          src={synoriqLogo}
          alt="Synoriq Logo"
          style={{ height: 31.36, width: 164.66 }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
