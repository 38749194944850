import { useRoutes } from "react-router-dom";

import { ROUTES_PATH } from "./Routes";
import Layout from "../component/Layout/layout";

const AppRouter = () => {
  const ROUTES = ROUTES_PATH;

  const routes = useRoutes(ROUTES);

  return <Layout>{routes}</Layout>;
};

export default AppRouter;
