import { Box, Grid, Stack } from "@mui/material";
import frameLogo from "../../assets/images/frameLogo.svg";
import Header from "../Header/header";

const Layout = ({ children }: any) => {
  return (
    <Stack height="100vh">
      <Box sx={{ flex: 1 }}>
        <Grid container height="100%" overflow="auto">
          <Grid
            item
            xs={12}
            sm={6}
            justifyContent="center"
            alignItems="center"
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              background:
                "linear-gradient(95.08deg, #010748 11.95%, #014ba3 108%)",
            }}
            height="-webkit-fill-available"
            padding="1.5em "
          >
            <img
              src={frameLogo}
              height="70%"
              width="70%"
              style={{ objectFit: "cover" }}
              alt="client-logo"
            />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Header />
            <Box
              sx={{
                height: "calc(100% - 10%)",
                width: { xs: "100%", md: "60%" },
                margin: "auto",
                display: "flex",
                alignSelf: { md: "center", sm: "center" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  padding: { xs: "1em 1em 1em 1em", md: "1em 0em 0em 0em" },
                }}
              >
                {children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Layout;
