import { ElementType, lazy, Suspense } from "react";

export const Loadable = (Component: ElementType) => () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("../pages/LoginPage/loginPage")));

export const ROUTES_PATH = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
];
